import React, { useState } from "react";
import { useFiles } from "./context";
import { Table, Card, Button, ButtonGroup } from "react-bootstrap";

const Dropbox = require("dropbox");

const downloadFile = (fileId: string) => {
  var dropboxToken =
    "psGJZ3zkJuYAAAAAAAAAAaCqDUVwLRXG2QS4GvRrLVAZRO6hLRiP4neIuaR1NB93";

  var dbx = new Dropbox.Dropbox({ accessToken: dropboxToken });
  dbx.filesDownload({ path: fileId }).then((response: any) => {
    console.log(response);
    const blob = response.result.fileBlob;
    const a = document.createElement("a");
    a.setAttribute("download", response.result.name);
    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.click();
  });
};
export const FileTable: React.FC = () => {
  const [category, setCategory] = useState("all");
  const { files, filters } = useFiles();
  const filteredFiles = files
    .filter((file) =>
      filters.company === 0 ? true : file.company_id === filters.company
    )
    .filter((file) => (filters.year === 0 ? true : file.year === filters.year));
  const categories = Array.from(new Set(filteredFiles.map((x) => x.category)));

  return (
    <Card.Body>
      <ButtonGroup className="w-100 d-none d-xl-block">
        <Button
          className={`tab-button`}
          onClick={() => setCategory("all")}
          size="sm"
        >
          Todo
        </Button>
        {categories.map((c, k) => {
          let active = c === category ? "active" : "";
          return (
            <Button
              key={k}
              className={`tab-button ${active}`}
              onClick={() => setCategory(c)}
              size="sm"
            >
              {c}
            </Button>
          );
        })}
      </ButtonGroup>
      <Table size="sm" style={{ fontSize: "0.8em" }}>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Compañia</th>
            <th>Año</th>
            <th>Idioma</th>
            <th>Descargar</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles
            .filter((x) =>
              category === "all" ? true : category === x.category
            )
            .map((file, k: number) => (
              <tr key={k}>
                <td>{file.file_name}</td>
                <td>{file.company.name}</td>
                <td>{file.year}</td>
                <td>{file.lang}</td>

                <td>
                  <Button href="#" onClick={(e) => downloadFile(file.file)}>
                    Descargar
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Card.Body>
  );
};
